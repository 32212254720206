
import { Component } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Header from "@/components/Header.vue";
import CheckAdminLogin from "@/utils/CheckAdminLogin";
import FacilityService from "@/services/facilities";
import { OperatorData } from "@/@types/management";
import { deleteNullProp } from "@/utils/Util";
import Button from "@/components/Button.vue";

@Component({
  name: "FacilityManagementStaffEdit",
  components: {
    Table,
    Header,
    ManagementFooter,
    Button,
  },
})
export default class FacilityManagementStaffEdit extends CheckAdminLogin {
  private search = "";
  private loading = false;
  private tableLoading = false;
  private facilityHeaders = [
    { text: "アカウントID", value: "id" },
    { text: "名前", value: "name" },
    { text: "メールアドレス", value: "email" },
  ];
  private operators = [];
  private facilityOperators = [];
  private selectedInfo: OperatorData | null = null;
  private showModal(name: string) {
    this.$modal.show(name);
  }
  private hospitalName = "";

  private closeModal(name: string) {
    this.$modal.hide(name);
  }
  private openDetailInfo(info: any) {
    this.selectedInfo = info;
    this.showModal("delete");
  }

  private async getOperators() {
    const res = await FacilityService.getOperators();
    this.operators = res.data.data;
    this.operators = this.operators.filter((user: OperatorData) => {
      if (user.facility_ids && !user.facility_ids.includes(this.$route.params.facility_id)) {
        return user;
      } else if (!user.facility_ids) {
        return user;
      }
    });
  }

  private async getOperatorsByFacilityId() {
    const res = await FacilityService.getOperatorsByFacilityId(this.$route.params.facility_id);
    this.facilityOperators = res.data.data;
  }
  private async getFacilityDetail() {
    const res = await FacilityService.get(this.$route.params.facility_id);
    this.hospitalName = res.data.data[0].name;
  }

  // private async getOperatorsByFacilityIdAndFiltered() {
  //   const res = await FacilityService.getOperatorsByFacilityId(this.$route.params.facility_id);
  //   const operators = res.data.data;
  //   console.log(operators);
  //   console.log(this.users);
  //   const mergeArray = [...operators, ...this.users];
  //   const mergeArrayIds = mergeArray.map((item) => item.id);
  //   //重複してないものだけ返す
  //   let filteredIds = mergeArrayIds.filter(function (val, idx, arr) {
  //     return arr.indexOf(val) === arr.lastIndexOf(val);
  //   });
  //   this.users = this.users.filter((user: OperatorData) => filteredIds.includes(user.id));
  // }

  private async mounted() {
    this.tableLoading = true;
    await this.getFacilityDetail();
    await this.getOperators();
    await this.getOperatorsByFacilityId();
    this.tableLoading = false;
  }
  private async deleteOperator() {
    try {
      this.loading = true;
      const operator = this.selectedInfo;
      const id = operator?.id;
      if (operator) {
        operator.facility_ids.splice(operator.facility_ids.indexOf(this.$route.params.facility_id), 1);
      }
      const payload = deleteNullProp(operator);
      if (!payload.facility_ids) {
        payload.facility_ids = [];
      }
      if (id) await FacilityService.updateOperator(id, payload);
      await this.getOperators();
      await this.getOperatorsByFacilityId();
      this.closeModal("delete");
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      if (error.response.data.error_msg.includes("Not Found operator")) {
        this.closeModal("delete");
        await this.getOperators();
        await this.getOperatorsByFacilityId();
      }
      throw new Error(error);
    }
  }
  private async addOperator(data: OperatorData) {
    try {
      this.loading = true;
      const operator = data;
      const id = operator.id;
      if (operator.facility_ids) {
        operator.facility_ids.push(this.$route.params.facility_id);
      } else {
        operator.facility_ids = [this.$route.params.facility_id];
      }
      const payload = deleteNullProp(operator);
      if (id) await FacilityService.updateOperator(id, payload);
      await this.getOperators();
      await this.getOperatorsByFacilityId();
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      if (error.response.data.error_msg.includes("Not Found operator")) {
        this.showModal("add-error");
      }
      throw new Error(error);
    }
  }
  private usersCustomFilter(value: any, search: string, item: any) {
    return (
      !!search &&
      (item.last_name?.concat(item.first_name).includes(search) ||
        item.first_name.includes(search) ||
        item.id.includes(search) ||
        item.email.includes(search))
    );
  }
}
